<template>
  <b-overlay :show="loading">
    <div>
      <table-component
        :show-form="false"
        url-create-form="/customers/create"
        url-edit-form="/customers/edit"
        :model="model"
      >
        <template v-slot:actions>
          <a
            :href="downloadUrl"
            class="btn btn-outline-primary"
          >
            <feather-icon icon="DownloadIcon" />
            Descargar formato
          </a>

          <button
            class="btn btn-outline-primary"
            @click="selectFile"
          >
            <feather-icon icon="UploadIcon" />
            Importar clientes
          </button>
        </template>
      </table-component>

      <input
        ref="file-selector"
        type="file"
        style="display: none;"
        @change="importCustomers"
      >

    </div>
  </b-overlay>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import Customer from '@/dc-it/models/Customer'
import { BOverlay } from 'bootstrap-vue'
import GeneralService from '@/dc-it/services/GeneralService'

export default {
  name: 'UsersView',
  components: { TableComponent, BOverlay },
  data() {
    return {
      model: Customer,
      loading: false,
    }
  },
  computed: {
    downloadUrl() {
      return `${process.env.VUE_APP_API_URL}/formatos/formato_clientes.xlsx`
    },
  },
  methods: {
    selectFile() {
      this.$refs['file-selector'].click()
    },
    async importCustomers() {
      const el = this.$refs['file-selector']
      if (el.files && el.files[0]) {
        const file = el.files[0]
        const reader = new FileReader()
        const { name } = file
        reader.onload = element => {
          this.loading = true
          GeneralService.getInstance().save('customers/import_excel', { excel: element.target.result })
            .then(response => {
              this.loading = false
              if (response.result === 'error') {
                this.$bvToast.toast(response.error, {
                  title: 'Ha ocurrido un error',
                  variant: 'danger',
                  solid: true,
                })
              } else {
                this.$bvToast.toast('Clientes importados correctamente', {
                  title: 'Importación exitosa',
                  variant: 'success',
                  solid: true,
                })
              }
              this.$refs['file-selector'].value = null
            })
            .catch(() => {
              this.loading = false
              this.$refs['file-selector'].value = null
            })
        }

        if ((/(.xlsx)$/i).exec(name)) {
          reader.readAsDataURL(file)
        } else {
          this.$bvToast.toast('El archivo seleccionado no es compatible', {
            title: 'Error en el archivo',
            variant: 'danger',
            solid: true,
          })
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
